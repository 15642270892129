
export default {
	data() {
    return {
    	
    }
  },
  props: ['company'],
  computed:{
    notInUserDownloadedIds() {
      return !this.$store.state.user.downloaded_ids.includes(this.$store.state.company.company.id)
    }
  }
}