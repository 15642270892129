
export default {
	data() {
    return {
    	
    }
  },
  props: ['company'],
  computed:{
    notInUserFavori() {
      return !Object.keys(this.$store.state.user.favoris_ids).includes(this.$store.state.company.company.id.toString())
    }
  }
}