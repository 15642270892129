import _ from 'lodash'

import FicheInfo_matieres from './fiche_info_matieres.vue'
import FicheInfo_logo from './fiche_info_logo.vue'
import FicheInfo_contacts from './fiche_info_contacts.vue'
import FicheInfo_chiffres_cles from './fiche_info_chiffres_cles.vue'
import FicheInfo_informations from './fiche_info_informations.vue'
import FicheInfo_description from './fiche_info_description.vue'
import FicheInfo_capacites from './fiche_info_capacites.vue'
import FicheInfo_competences from './fiche_info_competences.vue'
import FicheInfo_marches from './fiche_info_marches.vue'
import FicheInfo_familles_principale_secondaires from './fiche_info_familles_principale_secondaires.vue'
import FicheInfo_familles_principale_secondaires_capacites from './fiche_info_familles_principale_secondaires_capacites.vue'
import FicheInfo_commentaire_sourcing from './fiche_info_commentaire_sourcing.vue'

export default {
	data() {
		return {
			company: this.$store.state.company.company,
			onglet_actif: 0,
			liste_onglets: ['description', 'matieres', 'contacts', 'notes'],
			voir_plus: false
		}
	},
	created(){
		
	},
	methods: {
		// 'contacts', 'chiffres_cles', 'informations', 'description', 'capacites', 'competences', 'marches', 'familles_principale_secondaires', 'commentaire_sourcing',
		get_onglet_description(){
			
			let self       = this;
			let components = [];

			//AS--> Champs de base
			let fields = ['chiffres_cles', 'familles_principale_secondaires', 'familles_principale_secondaires_capacites', 'competences', 'capacites', 'description', 'informations', 'marches']
			let _components = _.intersection(this.$store.getters.getFicheInfos, fields);

			if(_components.length){
				_components.forEach(function(component){
					if(self['check_'+component]()) components.push(component)
				})
			}

			//AS--> Champs voir plus
			_components = _.intersection(this.$store.getters.getFicheInfosPlus, fields);

			if(_components.length){

				let voirplus_components = [];
				_components.forEach(function(component){
					if(self['check_'+component]()) voirplus_components.push(component)
				})
				components.push(voirplus_components)

			}

			if(components.length){
				return {
					code: 'description',
					name : this.$t('fiche_entreprise_onglet_description_titre'),
					components
				}
			}

			return null;
		},
		get_onglet_matieres(){

			if(this.$store.getters.fonctionnement == 'dechets' && this.showDechets){
				return {
					code: 'matieres',
					name : this.i18n(this.$store.state.ui.sourcing.nom_dechets) || this.$t('modal_company_dechets_label_dechet'),
					components: ['matieres']
				}
			}

			return null;
		},
		get_onglet_contacts(){
			let self       = this;
			let components = [];

			let fields = ['contacts']
			let _components = _.intersection(this.$store.getters.getFicheInfos, fields);
			
			if(_components.length){
				_components.forEach(function(component){
					if(self['check_'+component]()) components.push(component)
				})
			}

			if(components.length){
				return {
					code: 'contacts',
					name : this.$t('fiche_entreprise_onglet_contacts_titre'),
					components
				}
			}

			return null;
		},
		get_onglet_notes(){
			let self       = this;
			let components = [];

			let fields = ['commentaire_sourcing']
			let _components = _.intersection(this.$store.getters.getFicheInfos, fields);
			
			if(_components.length){
				_components.forEach(function(component){
					if(self['check_'+component]()) components.push(component)
				})
			}

			if(components.length){
				return {
					code: 'notes',
					name : this.$t('fiche_entreprise_onglet_notes_titre'),
					components
				}
			}

			return null;
		},

		showDechets(){
			let self = this;
			let show = false;
			if(this.company.familles_ids){
				this.company.familles_ids.forEach(function(famille_id){
					_.forEach(self.$store.state.ui.sourcing.familles, function(famille){
						if(famille.id == famille_id && famille.affichage_liste == 1) show = true;
					})
				})
			}
			return show;
		},

		check_description(){
			let canEdit = this.$store.getters.userCanEditCompany('description', this.company.id)
			return this.company.complement.description || this.$store.state.ui.sourcing.anonymize || canEdit
		},

		check_chiffres_cles(){
			let self    = this;
			if(this.company.complement && (this.donneesSectoriellesNonEditable.length || this.donneesSectoriellesEditable.length)) return true;
		},

		check_familles_principale_secondaires(){
			let self    = this;
			let canEdit = false;

			//AS--> Si le menu d'édition de l'entreprise est activé et que l'entreprise fait parti des entreprises liées à l'internaute et si de champ peut être modifié
			if(this.$store.getters.getMenusMonentreprise.length && this.$store.state.user.user.mesentreprises && this.$store.state.user.user.mesentreprises.length && this.$store.getters.getFicheInfosEditions.includes('familles_principale_secondaires')){
				this.$store.state.user.user.mesentreprises.forEach(function(ent){
					if(ent.id == self.company.id) canEdit = true;
				})
			}

			return (this.company.complement.familles_principales_ids && this.company.complement.familles_principales_ids.length) || (this.company.complement.familles_secondaires_ids && this.company.complement.familles_secondaires_ids.length) || canEdit
		},

		check_familles_principale_secondaires_capacites(){
			return this.check_familles_principale_secondaires()
		},

		check_competences(){
			let canEdit = this.$store.getters.userCanEditCompany('competences', this.company.id)
			return (this.company.complement.competences_ids && this.company.complement.competences_ids.length) || canEdit
		},

		check_capacites(){
			let self    = this;

			if(!this.company.complement.capacites) return false;
			let capacites = [];

			//AS--> Prend toutes les capacités définies dans le sourcing, dans l'ordre d'affichage
			self.$store.getters.getCapacitesIds.forEach(function(capacite_id_famille){
			  self.company.complement.capacites.forEach(function(capacite){
			    if(capacite.id == capacite_id_famille) capacites.push(capacite);
			  })
			})

			return capacites.length
		},

		check_informations(){
			let canEdit = this.$store.getters.userCanEditCompany('informations', this.company.id)
			return this.company.complement.presentation_fo || this.$store.state.ui.sourcing.anonymize || canEdit
		},

		check_marches(){
			let canEdit = this.$store.getters.userCanEditCompany('marches', this.company.id)
			return (this.company.complement.marches_ids && this.company.complement.marches_ids.length) || canEdit
		},

		check_contacts(){
			let self    = this;
			let canEdit = false;

			return (this.company.complement && this.company.complement.contacts && this.company.complement.contacts.length) || this.$store.state.ui.sourcing.anonymize || this.$store.getters.userCanEditCompany('contacts_lies', this.company.id)
		},

		check_commentaire_sourcing(){
			let self    = this;
			let canEdit = true;

			return this.company.complement.commentaire_sourcing || this.$store.state.ui.sourcing.anonymize || canEdit
		}
	},
	computed:{
		onglets(){
			let self = this
			let onglets = []

			if(this.company.complement && this.$store.getters.getFicheInfos){

				this.liste_onglets.forEach(function(_onglet){
					let onglet = self['get_onglet_'+_onglet]();
					if(onglet) onglets.push(onglet);
				})

			}

			return onglets

		},

		//AS--> Liste des variables d'activité de l'entreprise qui ne sont pas modifiables
	    donneesSectoriellesNonEditable(){
	      let self = this;

	      let donnees = [];
	      _.forEach(this.$store.state.ui.sourcing.variables, function(val, index){
	        if(
	          index != 'nbr_salaries'
	          && index != 'billing_data'
	          && !val.editable_fo 
	          && (
	            (typeof self.company.complement[index] != 'undefined' && self.company.complement[index] !== null) 
	            || (typeof self.company[index] != 'undefined' && self.company[index] != null)
	          )
	        ) {

	          let valeur = '';
	          if(typeof self.company.complement !== 'undefined' && typeof self.company.complement[index] !== 'undefined'){
	            valeur = _.clone(self.company.complement[index]);
	          }else if(typeof self.company[index] !== 'undefined'){
	            valeur = _.clone(self.company[index]);
	          }

	          let _donnee = {
	            'label' : val.label,
	            'valeur' : valeur,
	            'ordre' : val.ordre,
	            'code' : index
	          }
	          donnees.push(_donnee);
	        }
	      });
	      donnees = _.orderBy(donnees, ['ordre']);

	      return donnees;
	    },
	    //AS--> Liste des variables d'activité de l'entreprise qui sont modifiables + celles qui sont modifiables mais que l'entreprise ne possède pas
	    donneesSectoriellesEditable(){
	      let self = this;

	      let donnees = [];
	      _.forEach(this.$store.state.ui.sourcing.variables, function(val, index){
	        if(val.editable_fo){

	          let valeur = null;
	          if(typeof self.company.complement !== 'undefined' && typeof self.company.complement[index] !== 'undefined'){
	            valeur = _.clone(self.company.complement[index]);
	          }else if(typeof self.company[index] !== 'undefined'){
	            valeur = _.clone(self.company[index]);
	          }

	          let _donnee = {
	            'label' : val.label,
	            'valeur' : valeur,
	            'ordre' : val.ordre,
	            'code' : index
	          }
	          if(_donnee.valeur === null && index.slice(-5) == '_bool') _donnee.valeur = -1;
	          
	          donnees.push(_donnee);
	        }
	      });
	      
	      donnees = _.orderBy(donnees, ['ordre']);

	      return donnees;
	    }
	},
	components:{
		FicheInfo_matieres,
		FicheInfo_logo,
		FicheInfo_contacts,
		FicheInfo_chiffres_cles,
		FicheInfo_informations,
		FicheInfo_description,
		FicheInfo_capacites,
		FicheInfo_competences,
		FicheInfo_marches,
		FicheInfo_familles_principale_secondaires,
		FicheInfo_familles_principale_secondaires_capacites,
		FicheInfo_commentaire_sourcing,
	}
}