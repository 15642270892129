import axios from '../common/axios.js'
import Contacts from './blocs/contacts.vue'
import _ from 'lodash'
import queryString from 'query-string'
import Content from '../common/content_structure/_base.vue'

import PictoHautDroite_telechargement from './fiche/picto_haut_droite_telechargement.vue'
import PictoHautDroite_contact from './fiche/picto_haut_droite_contact.vue'
import PictoHautDroite_favoris from './fiche/picto_haut_droite_favoris.vue'
import FicheInfo_logo from './fiche/fiche_info_logo.vue'
import OngletsInfos from './fiche/onglets_infos.vue'

import ContentEditable from '../common/forms/ContentEditable.vue'

import EditionMatieres from '../components/fiche/edition_matieres.vue'

export default {
	data() {
		return {
			company: {},
			prev_company_id: null,
			nace: {},
			cancelSource: null,
			loading_web: false,
			loading_data: false,
			loading: false,
			ajout_favoris: 0,
			signaler_open: false,
			signaler_merci: false,
			signaler: {},
			signaler_erreurs: {},
			moins6semaines: (Date.now()-6*7*24*60*60*1000)/1000,
			edition_enseigne: false,
			edition_enseigne_valeur: null,
			editions: [],
			erreurs: {}
		}
	},
	created() {
		var self = this;

		//AS--> Met en place l'entreprise
		self.company = self.$store.state.company.company
		self.nace = self.$store.state.ui.code_naces[self.$store.state.company.company.nace_id]

		self.loadCompany(true);

		self.unwatch_company_company = self.$store.watch(function() {
			//AS--> Défini l'entreprise en hover
			//			if(self.$store.state.company.company.id !== null) self.$store.dispatch('set_company_hover', self.$store.state.company.company.id);
			return self.$store.state.company.company
		}, function(after, before) {
			self.editions = []
			self.company = self.$store.state.company.company

			self.ajout_favoris = 0;

			if (self.$store.state.company.company.id !== null) {
				//AS--> Défini l'entreprise en hover
				//				self.$store.dispatch('set_company_hover', self.$store.state.company.company.id);
				self.nace = self.$store.state.ui.code_naces[self.$store.state.company.company.nace_id]
				if(after.id != before.id)
					self.loadCompany()
			} else {
				self.nace = null;
			}
		});

		//AS--> Ajout un événement sur le body pour fermer la modale
		// document.querySelector(".c-results-page").addEventListener('click', self.close_modal);
	},
	mounted() {
		var self = this;

		//AS--> Défini le déchet principal par défaut
		_.forEach(self.dechets, function(d) {
			if (d.data.id == self.$store.state.filters.filters.main_dechet_id) {
				self.dechet_actif = d;
			}
		})

		this.updateVoirPlus();

	},
	updated: function(){
		let self = this;
		this.updateVoirPlus();
	},
	beforeDestroy: function() {
		var self = this;
		self.unwatch_company_company();
		// document.querySelector(".c-results-page").removeEventListener('click', self.close_modal);
	},
	watch: {
		"$store.state.company.company": function(after, before){
			this.company = after;
		},
		loading_data: function(after, before){
			this.erreurs = {}
			if(after === false && this.loading_web === false) this.loading = false
		},
		loading_web: function(after, before){
			this.erreurs = {}
			if(after === false && this.loading_data === false) this.loading = false
		}
	},
	methods: {
		updateVoirPlus(){
			//AS--> Affiche ou masque les infos supplémentaires avec le bouton voir plus / voir moins
			if(this.company && this.company.complement && this.company.complement.id != this.prev_company_id){
				//AS--> Une fois les compléments chargés, on regarde si l'id dans les compléments a changé
				//AS--> S'il a changé, on mémorise le changement
				this.prev_company_id   = this.company.complement.id;
				//AS--> On dit à la zone d'affichage des infos complémentaires de ne pas s'afficher
				this.voir_plus_affiche = false;
				//AS--> S'il y a des composants chargés, on dit à la zone de s'afficher
				if(document.querySelectorAll("#infos-more > div").length > 1) this.voir_plus_affiche = true;
			}
		},
		close_modal(event) {
			if (event.target.closest('.item-company') == null && event.target.closest('.c-modal-company') == null && event.target.closest('.vue-map') == null) {
				if(this.$store.state.ui.content == 'result' || this.$store.state.ui.content == 'favoris'){
					this.$store.dispatch('setCompany', null);
					this.$store.commit('UI_SET_COMPANY_INEX_DATA', null);
				}
			}
		},
		show_prev() {
			this.$store.commit('UI_SET_COMPANY_INEX_DATA', null);
			this.$store.dispatch('setCompany', this.$store.state.company.company.prev);
		},
		show_next() {
			this.$store.commit('UI_SET_COMPANY_INEX_DATA', null);
			this.$store.dispatch('setCompany', this.$store.state.company.company.next);
		},
		getDistance() {
			return this.company.dist;
		},
		loadCompany(force) {
			var self = this;

			if(this.$store.state.company.company_inexdata && this.$store.state.company.company_inexdata.general_info && this.$store.state.company.company && this.$store.state.company.company_inexdata.general_info.identifiant != this.$store.state.company.company.identifiant){
				this.$store.commit('UI_SET_COMPANY_INEX_DATA', null);
			}

			_.forEach(this.$store.state.user.favoris, function(favori) {
				if (self.company.id == favori.id) {
					self.ajout_favoris = 2;
				}
			})

			if (force || typeof self.company.complement == 'undefined') {
				//AS--> Indique un chargement en cours
				self.loading      = true;
				// self.loading_web  = true;
				self.loading_data = true;

				this.$store.dispatch('loadiNexCompany')
				.then(function(company){
					self.company = company
					self.loading_data = false;
				})
				.finally(function(){
					self.loading_data = false;
				})

			}
		},
		dechets_caracteristiques() {

		},
		numberFormat(number) {
			if (number >= 0 && number <= 1) {
				return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(number);
			} else {
				return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(number);
			}
		},
		addLiaison() {
			let self = this;
			self.$store.dispatch('switchFavori', self.company.id);
		},
 		signaler_send(){
			this.signaler_erreurs = {};
			if(!this.signaler.nom || this.signaler.nom.length < 4) this.signaler_erreurs.nom = 1;
			if(!this.signaler.contact || this.signaler.contact.length < 6) this.signaler_erreurs.contact = 1;
			if(!this.signaler.contenu || this.signaler.contenu.length < 10) this.signaler_erreurs.contenu = 1;

			if(!this.signaler_erreurs.nom && !this.signaler_erreurs.contact && !this.signaler_erreurs.contenu){
				this.signaler_merci = true;
				
				let data = new FormData();
				data.append('nom', this.signaler.nom);
				data.append('contact', this.signaler.contact);
				data.append('contenu', this.signaler.contenu);

				axios.post('/plugin_projet/plugin_projet_iannuaire/signaler/'+this.company.id, data);
			}
		},
		ars(){
			let self = this;
			this.$dialog.confirm(this.$t('ars_confirm'), {loader: true})
			.then(dialog => {
				//AS--> Demande la suppression du favoris en DB
				axios.get('/plugin_projet/plugin_projet_iannuaire/ars_agrement/'+self.company.id)
				.then(function (response) {

					//AS--> Ferme l'ancienne boîte de dialogue
					dialog.close();

					//AS--> En cas de succès, modifie la valeur stockée
					if(response.data == 'OK'){
						self.$dialog.alert(self.$t('ars_ok'));
						self.loadCompany(true);
					}
				}).catch(function (error) {})

			});
		},
		isNew(added){
			return added > this.$store.state.ui.sourcing.based && added > this.moins6semaines
		},
		getDateAffich(_date){
			let __date = new Date(_date*1000);
			return __date.getDate() + '/' + (__date.getMonth()+1) + '/' + __date.getFullYear();
		},
		getCadastreUrl(){
			let adresse = this.company.complement.adresse1.match(/^(\d*) *-* *(.*)/)

			let d = {
				numeroVoie: adresse[1],
				nomVoie: adresse[2],
				ville: this.company.v,
				codePostal: this.company.complement.codep,
				codeDepartement: '0'+this.company.complement.codep.toString().substring(0, 2)
			}

			let params = queryString.stringify(d)

			return 'https://cadastre.gouv.fr/scpc/rechercherPlan.do?'+params;
		},
		getEmailVerifie(){
			return this.company.complement.email_verifie ? this.$t('email_verifie') : this.$t('email_non_verifie');
		},
		getUrlAbsolue(url){
			return url.substring(0, 4) == 'http' ? url : 'http://'+url;
		},
		getUrlShort(url){
			//AS--> Limite url à 40 caractères
			if(url.length > 40) url = url.substring(0, 37)+'...';
			return url
		},
		getPicto(dechet){
			if(dechet.picto_personnalise) return dechet.picto_personnalise;
			let dechet_picto = this.$store.getters.getDechetField(dechet.id, 'picto');
			return dechet_picto ? dechet_picto : null;
		},
		editAndFocusEnseigne(){
			this.edition_enseigne_value = this.getEnseigne
			this.edition_enseigne       = true
		},
		changeDataEnseigne(){
			let self = this;

			self.company.complement['enseigne'] = this.edition_enseigne_value;

			axios.get(
			    '/plugin_projet/plugin_projet_iannuaire/set_entreprise_variable/' + self.company.id + '/enseigne/' + this.edition_enseigne_value
			  )
			  .then(function(response) {
			    if (response.data != 'CONNEXION') {
			      //AS--> Faire quelque chose ? 
			    }
			  }).catch(function(error) {})

			this.edition_enseigne = false;
		},
		canEdit(champ){
			return this.$store.getters.userCanEditCompany(champ, this.company.id)
		},
		editionOn(champ){
			let self = this
			
			let editions = _.clone(this.editions)
			editions.push(champ)
			this.editions = editions

			if(['tel1','email'].includes(champ)){
				setTimeout(function(){
					self.$refs['field_'+champ].focus()
				}, 0)
			}
		},
		editionSave(champ, checkEmpty){

			let value = null

			if(['tel1','email'].includes(champ)){
				value = String(this.$refs['field_'+champ].textContent).trim()
			}else{
				value = String(this.$refs['field_'+champ].$el.textContent).trim()
			}

			let before = String(typeof this.company.complement[champ] != 'undefined' ? this.company.complement[champ] : this.company[champ]).trim()

			if(checkEmpty && before !== '' && before !== 'null' && before !== null && before !== undefined && (value === '' || value === null)){

				this.erreur(champ, this.$t('saisir_valeur'))

			}else{

				this.erreur(champ, null)

				if(before != value){

					//AS--> Mise à jour du serveur
					this.$store.dispatch('entreprise_modifie_champ', {
						id: this.company.id,
						champ: champ,
						valeur: value
					})
				}

				let editions = _.clone(this.editions)
				editions = _.without(editions, champ)
				this.editions = editions
			}
		},
		erreur(champ, erreur){
			let erreurs = _.cloneDeep(this.erreurs);
			erreurs[champ] = erreur;
			this.erreurs = erreurs
		}
	},
	computed: {
		showDechets(){
			let self = this;
			let show = false;
			if(this.company.familles_ids){
				this.company.familles_ids.forEach(function(famille_id){
					_.forEach(self.$store.state.ui.sourcing.familles, function(famille){
						if(famille.id == famille_id && famille.affichage_liste == 1) show = true;
					})
				})
			}
			return show;
		},
		favorisState(){
			let state = 0
			if(Object.keys(this.$store.state.user.favoris_ids).includes(this.company.id.toString())){
				state = this.$store.state.user.favoris_ids[this.company.id]
			}

			return state
		},
		getFamillesSvg(){
			let svg = [];

			if(!this.company.familles_ids && this.company.complement && this.company.complement.familles_ids && this.company.complement.familles_ids.length) this.company.familles_ids = this.company.complement.familles_ids;
			if(this.company.familles_ids){
				let familles_ids = this.company.familles_ids
				_.forEach(this.$store.state.ui.sourcing.familles, function(famille){
					if(famille.parent_id != 0 && familles_ids.includes(famille.id) && famille.svg_dark){
						svg.push(famille.svg_dark)
					}
				})
			}

			return svg;
		},
		hasArs(){
			let hasArs = false;
			if(this.company.complement.donnees_sectorielles){
				this.company.complement.donnees_sectorielles.forEach(function(data){
					if(data.code == 'dyn_ars' && data.value == 1) hasArs = true;
				})
			}
			return hasArs;
		},
		infosAdministratives(){
			let self       = this;
			let complement = this.company.complement;
			let infos      = this.$store.getters.getFicheAdministratif;
			let _infos     = [];
			if(infos.length){
				infos.forEach(function(info){
					switch(info){
						case 'nace': 
							if(self.nace) _infos.push(self.$t('modal_company_code_nace') + ' : ' + self.nace.code+' - '+self.nace.titre);
							break;
						case 'naf': 
							if(complement.naf) _infos.push(self.$t('modal_company_code_naf') + ' : ' + complement.naf);
							break;
						case 'nace_parent': 
							if(self.nace) _infos.push(self.nace.parent_0_nom);
							break;
						case 'siret': 
							if(complement && complement.identifiant) _infos.push(self.$t('modal_company_siret') + ' : ' + complement.identifiant);
							break;
					}
				});
			}
			return _infos.join('<br />');
		},
		getEnseigne(){
			return this.company.complement && this.company.complement.enseigne ? this.company.complement.enseigne : this.company.nom
		},
		canShowChiffresCles(){
			return this.$store.getters.canShowChiffresCles
		}
	},
	components: {
		Content,
		Contacts,
		PictoHautDroite_telechargement,
		PictoHautDroite_contact,
		PictoHautDroite_favoris,
		FicheInfo_logo,
		OngletsInfos,
		ContentEditable,
		EditionMatieres
	}
}