import _ from 'lodash'

import EditionMatieres from '../editions/edition_matieres.vue'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-results-page").addEventListener('click', this.close_event);
	},
	beforeDestroy: function() {
		document.querySelector(".c-results-page").removeEventListener('click', this.close_event);
	},
	methods: {
		close_event(event){
			let self = this
			setTimeout(function(){
				if (event.target.closest('.c-menu')) self.close()
			}, 10)
		},
		close(){
			//AS--> Recharge les données de l'entreprise en cours
			this.$store.dispatch('loadiNexCompany');

			//AS--> Ferme la modale
			this.$store.commit('UI_SET_COMPANY_EDIT_MATIERES_MODALE', false)

			//AS--> Réinitialise la matière en cours d'édition
			this.$store.commit('UI_SET_COMPANY_EDITION_MATIERE', null)
		}
	},
	computed:{
		
	},
	components:{
		EditionMatieres
	}
}