import CustomSelect from '../../common/forms/Select.vue'

export default {
	data() {
		return {
			company: this.$store.state.company.company,
			familles_secondaires_ids: [], 	//AS--> Si des familles ne faisant pas parti du sourcing sont envoyées, elles ne seront pas modifiée en FO par l'internaute mais seront toujours envoyées à l'API pour enregistrement grace à la fonction switchValue qui ne gère que les ids éditables
			familles_secondaires_id0: null,
			familles_secondaires_id1: null,
			edition: false,
			voir_plus: false,
			limitation: false,
			nbr_familles_secondaires_max: 2,
			capacite_edition_id: null,
			capacite_edition_value: null,
			capacities_edited: []
		}
	},
	mounted(){
		//AS--> Ajout un événement sur le body pour fermer la modale
		document.querySelector(".c-modal-company").addEventListener('click', this.capacite_edition_id = null);
	},
	beforeDestroy: function() {
		document.querySelector(".c-modal-company").removeEventListener('click', this.capacite_edition_id = null);
	},
	created(){

		//AS--> Initialise les données
		this.init()

		//AS--> Active l'édition par défaut s'il est possible d'éditer
		if(this.canEdit) this.edition = true

	},
	watch:{
		"$store.state.company.company"(newValue, oldValue){
			this.company = this.$store.state.company.company
			this.init()
		},
		"familles_secondaires_id0"(newValue, oldValue){
			let new_familles_secondaires_ids = _.cloneDeep(this.familles_secondaires_ids);
			//AS--> Si la nouvelle valeur est vide, on retire son entrée dans le tableau et on décale la valeur 2
			if(!newValue){
				new_familles_secondaires_ids = [new_familles_secondaires_ids[1]];
				if(this.familles_secondaires_id1){
					this.familles_secondaires_id0 = this.familles_secondaires_id1
					this.familles_secondaires_id1 = null
				}
			}else{
				new_familles_secondaires_ids[0] = newValue;
			}
			this.familles_secondaires_ids = new_familles_secondaires_ids;
		},
		"familles_secondaires_id1"(newValue, oldValue){
			let new_familles_secondaires_ids = _.cloneDeep(this.familles_secondaires_ids);
			//AS--> Si la nouvelle valeur est vide, on retire son entrée dans le tableau
			if(!newValue){
				new_familles_secondaires_ids = _.without(new_familles_secondaires_ids, new_familles_secondaires_ids[1]);
			}else{
				new_familles_secondaires_ids[1] = newValue;
			}
			this.familles_secondaires_ids = new_familles_secondaires_ids;
		}
	},
	methods:{
		//AS--> Initialisation des données d'après les données actuelles de l'entreprise
		init(){
			this.familles_secondaires_ids = []
			//AS--> Ajoute les familles secondaires au démarrage
			if(this.company.complement.familles_secondaires_ids && this.company.complement.familles_secondaires_ids.length){
				this.company.complement.familles_secondaires_ids.forEach((familleId, index) => {
					this.switchValue(familleId);
				});
				if(this.familles_secondaires_ids[0]) this.familles_secondaires_id0 = this.familles_secondaires_ids[0] ? this.familles_secondaires_ids[0] : null
				if(this.familles_secondaires_ids[1]) this.familles_secondaires_id1 = this.familles_secondaires_ids[1] ? this.familles_secondaires_ids[1] : null
			}
			//AS--> Vide les caractéristiques
			this.capacities_edited = []
			this.capacite_edition_id = null
			this.capacite_edition_value = null
		},
		//AS--> Ouvre l'éditeur
		editionOn(){
			// this.familles_secondaires_ids = _.clone(this.company.complement.familles_secondaires_ids);
			// this.familles_secondaires_ids.forEach((familleId, index) => {
			// 	this.familles_secondaires_ids[index] = familleId.toString();
			// });
			this.edition = true;
		},

		//AS--> Ferme l'éditeur sans sauvegarder
		editionOff(){
			this.init()
		},

		switchValue(familleId){
			let self = this
			let nbr_familles_actives = _.intersection(this.familles_secondaires_ids, this.getFamillesIds).length;

			//AS--> Retire la famille
			if(this.familles_secondaires_ids.includes(familleId)){
				this.familles_secondaires_ids = _.without(this.familles_secondaires_ids, familleId);

			//AS--> Ajoute la famille
			}else{
				if(nbr_familles_actives >= this.nbr_familles_secondaires_max){
					this.limitation = true
					setTimeout(function(){
						self.limitation = false
					},2500)
				}else{
					this.familles_secondaires_ids.push(familleId);
				}
			}

		},

		//AS--> Enregistre le contenu de l'éditeur avant de le fermer
		editionSave(){
			let self = this;

			//AS--> Si des familles ont changé, on les enregistre
			if(this.familles_secondaires_hasChanged){
				this.$store.dispatch('entreprise_modifie_champ', {
					id: this.company.id,
					champ: 'familles_secondaires_ids',
					valeur: this.familles_secondaires_ids
				})
			}

			//AS--> Si des capacités ont changé, on les enregistre
			if(this.capacities_edited.length){
				this.capacities_edited.forEach((capacite, index) => {
					if(this.$store.state.ui.sourcing.capacites_all[capacite.capacite_id]){
						this.$store.dispatch('entreprise_modifie_capacite', {
							id: this.company.id,
							capacite_id: capacite.capacite_id,
							code: this.$store.state.ui.sourcing.capacites_all[capacite.capacite_id].code,
							valeur: capacite.value
						})
					}
				})
			}

			// this.edition = false;
		},

		getCapaciteValue(capacite_id){
			let capacite = _.find(this.company.complement.capacites, {id: capacite_id})
			if(capacite) return capacite.val
			else return null
		},

		getCapaciteValueShow(capacite_id){
			let capacite = null
			this.capacities_edited.forEach((_capacite, index) => {
				if(_capacite.capacite_id == capacite_id) capacite = _capacite.value
			})
			if(capacite === null) capacite = this.getCapaciteValue(capacite_id)
			return capacite
		},

		//AS--> Retourne la liste des familles disponibles en familles secondaires, excepté la famille secondaire de l'autre select
		getListFamilles_except(famille_secondaire_index){
			let except = null
			if(famille_secondaire_index == 0 && this.familles_secondaires_ids && this.familles_secondaires_ids.length >= 2){
				except = this.familles_secondaires_ids[1]
			}
			if(famille_secondaire_index == 1 && this.familles_secondaires_ids && this.familles_secondaires_ids.length >= 1){
				except = this.familles_secondaires_ids[0]
			}
			let values = _.cloneDeep(this.getListFamilles)
			values = values.filter((famille) => famille.value != except)
			return values
		},

		getFamilleCapacitesIds(famille_id){
			let self = this
			let capacites_ids = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.id == famille_id) capacites_ids = famille.capacites_ids
			})
			return capacites_ids;
		},

		capaciteEditionOn(capacite_id){
			let self = this

			//AS--> S'il y avait déjà une édition en cours, on la stoppe proprement
			if(this.capacite_edition_id) this.capaciteEditionOff()

			//AS--> On défini l'édition de la nouvelle capacité
			this.capacite_edition_id = capacite_id

			//AS--> On récupère la valeur de la capacité
			this.capacite_edition_value = this.getCapaciteValueShow(capacite_id)

			//AS--> On focus l'input
			setTimeout(function(){
				self.$refs.inputCapacite[0].focus()
			},50)
		},
		capaciteEditionOff(){

			//AS--> S'il y a eu un changement de valeur, on stocke la valeur modifiée dans un array temporaire
			if(this.capacite_edition_value != this.getCapaciteValue(this.capacite_edition_id)){
				let found = false
				this.capacities_edited.forEach((capacite, index) => {
					if(capacite.capacite_id == this.capacite_edition_id){
						this.capacities_edited[index].value = this.capacite_edition_value
						found = true
					}
				})
				if(!found){
					this.capacities_edited.push({
						capacite_id: this.capacite_edition_id,
						value: this.capacite_edition_value
					})
				}
			}else{
				this.capacities_edited = this.capacities_edited.filter((capacite) => capacite.capacite_id != this.capacite_edition_id)
			}

			this.capacite_edition_id = null
			this.capacite_edition_value = null
		}
	},
	computed:{
		canEdit(){
			return this.$store.getters.userCanEditCompany('familles_principale_secondaires', this.company.id)
		},
		getFamillePrincipaleIds(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_principales_ids.includes(famille.id)) familles.push(famille.id)
			})
			return familles;
		},
		getFamillesPrincipales(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_principales_ids.includes(famille.id)) familles.push(famille)
			})
			return familles;
		},
		getFamillePrincipale(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_principales_ids.includes(famille.id)) familles.push(famille.titre)
			})
			return familles.join(' / ');
		},
		getFamillesSecondaires(){
			let self = this
			let familles = [];
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && self.company.complement.familles_secondaires_ids.includes(famille.id) && !self.getFamillePrincipaleIds.includes(famille.id)) familles.push(famille)
			})
			return familles;
		},
		//AS--> Récupère les familles affectées au sourcing
		getFamilles(){
			return _.sortBy(_.cloneDeep(this.$store.state.ui.sourcing.familles), ['titre']);
		},
		//AS--> Récupère les ids des familles affectées au sourcing
		getFamillesIds(){
			return _.reduce(this.$store.state.ui.sourcing.familles, function(ids, famille){if(famille.parent_id) ids.push(famille.id); return ids}, []);
		},
		getListFamilles(){
			let self = this
			let familles = []
			_.forEach(this.getFamilles, function(famille){
				if(famille.parent_id != 0 && !self.getFamillePrincipaleIds.includes(famille.id)) familles.push({label: famille.titre, value: famille.id})
			})
			return familles;
		},
		familles_secondaires_hasChanged(){
			if(this.company.complement.familles_secondaires_ids){
				let actuelles_familles_actives = _.intersection(this.company.complement.familles_secondaires_ids, this.getFamillesIds);
				//AS--> Si la famille 1 a changé, return true
				if(this.familles_secondaires_ids[0] != actuelles_familles_actives[0]) return true;
				//AS--> Si la famille 2 a changé, return true
				if(this.familles_secondaires_ids[1] != actuelles_familles_actives[1]) return true;
			}
		},
		hasChanged(){
			//AS--> Si des familles ont été modifiées, return true
			if(this.familles_secondaires_hasChanged) return true
			//AS--> Si des capacités ont été modifiées, return true
			if(this.capacities_edited.length) return true

			return false
		}
	},
	components: {
		CustomSelect
	}
}