import ImageAuto from '../../common/ui/image_auto.vue'

export default {
	data() {
		return {
			attend_changement_image: false
		}
	},
	watch:{
		"$store.state.ui.upload_image"(newValue, oldValue){

			//AS--> Si un nouveau fichier est fourni, on envoie les données
			if(this.attend_changement_image && newValue){
				this.$store.dispatch('entreprise_modifie_logo', {
					id: this.company.id,
					image: newValue
				})
			}
		},
		"$store.state.ui.modal"(newValue, oldValue){
			this.attend_changement_image = newValue == 'upload_image';
		}
	},
	methods:{
		openImageUploader(){
			this.$store.commit('UI_SET_MODAL', {modal: 'upload_image'});
		}
	},
	computed:{
		canEdit(){
			return this.$store.getters.userCanEditCompany('logo', this.company.id)
		},
		company(){
			return this.$store.state.company.company
		}
	},
	components:{
		ImageAuto
	}
}